<script>

import { BCard, BCardTitle } from 'bootstrap-vue'
import { DxColumn, DxDataGrid } from 'devextreme-vue/data-grid'
import { DxDropDownButton } from 'devextreme-vue/drop-down-button'
import InvoicesDatasource from '@/data/invoices.datasource'
import InvoiceStatusCell from '@/views/account-setting/components/billing-plans/components/InvoiceStatusCell.vue'

export default {
  name: 'Invoices',
  components: {
    InvoiceStatusCell,
    DxDropDownButton,
    BCardTitle,
    DxColumn,
    DxDataGrid,
    BCard,
  },
  data() {
    return {
      datasource: InvoicesDatasource.getInstance(this.$store),
      noResultsFoundMessage: 'No invoices found',
    }
  },
  methods: {
    actions({ data }) {
      return [
        {
          data,
          text: 'Download Invoice',
          action: 'download',
          icon: 'download',
        },
        {
          data,
          text: 'View Invoice',
          action: 'view',
          icon: 'pdffile',
        },
      ]
    },
    onActionClick({ itemData }) {
      switch (itemData.action) {
        case 'download':
          this.download(itemData.data.document_url)
          break
        case 'view':
          window.open(itemData.data.hosted_invoice_url, '_blank')
          break
        default:
      }
    },
    download(url) {
      const link = document.createElement('a')
      link.href = url
      link.download = 'invoice.pdf'
      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
  },
}
</script>

<template>
  <b-card>
    <b-card-title>Invoices</b-card-title>
    <DxDataGrid
      ref="gridView"
      class="sxr-grid regular-text-color"
      :data-source="datasource"
      :show-borders="false"
      :show-row-lines="true"
      :show-column-lines="false"
      :remote-operations="true"
      :word-wrap-enabled="true"
      :show-column-headers="false"
      :no-data-text="noResultsFoundMessage"
      width="100%"
      height="500"
    >
      <DxColumn
        data-field="date"
        data-type="datetime"
      />
      <DxColumn
        data-field="status"
        cell-template="statusCell"
      />
      <DxColumn
        data-field="amount.display"
      />
      <DxColumn
        data-field="subscription_plan_name"
      />
      <DxColumn
        :show-in-column-chooser="false"
        :fixed="true"
        fixed-position="right"
        alignment="center"
        css-class="fixed-column-cell"
        type="buttons"
        width="70"
        cell-template="actionsCellTemplate"
      />
      <template #statusCell="{ data: cell }">
        <InvoiceStatusCell :value="cell.data.status" />
      </template>
      <template #actionsCellTemplate="{ data: cell }">
        <DxDropDownButton
          :split-button="false"
          :focus-state-enabled="false"
          :show-arrow-icon="false"
          :use-select-mode="false"
          :drop-down-options="{ width: 300 }"
          :items="actions(cell)"
          display-expr="text"
          icon="overflow"
          width="34"
          @item-click="onActionClick"
        />
      </template>
    </DxDataGrid>
  </b-card>
</template>

<style lang="scss">
@import '@/assets/scss/common/grid.scss';
.dark-layout .dx-popup-wrapper > .dx-overlay-content {
  border: 1px solid #404656;
  background: #353d55;
}
</style>
<style scoped lang="scss">

</style>
