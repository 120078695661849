<script>

import { BButton } from 'bootstrap-vue'
import PaymentMethodIcon from '@/components/PaymentMethodIcon.vue'

export default {
  name: 'Card',
  components: {
    PaymentMethodIcon,
    BButton,
  },
  props: {
    dummy: {
      type: Boolean,
      default: false,
    },
    cardId: {
      type: String,
      default: null,
    },
    holderName: {
      type: String,
      default: 'Card Holder Name',
    },
    number: {
      type: String,
      default: '',
    },
    expMonth: {
      type: Number,
      default: null,
    },
    expYear: {
      type: Number,
      default: null,
    },
    brand: {
      type: String,
      default: '',
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentYear() {
      return new Date().getFullYear() + 4
    },
    currentMonth() {
      return (new Date().getMonth() + 1).toString().padStart(2, '0')
    },
  },
  methods: {
    onEditClick() {
      this.$emit('on-edit-click', this.cardId)
    },
  },
}
</script>

<template>
  <div class="credit-card tw-flex tw-flex-col tw-gap-3 tw-p-5">
    <template v-if="dummy">
      <div><feather-icon
        icon="CreditCardIcon"
        size="34"
      /></div>
      <div>CARD HOLDER</div>
      <div class="tw-flex tw-justify-between">
        <div>
          **** **** **** 1234
        </div>
        <div>
          Card expires at {{ currentMonth }} / {{ currentYear }}
        </div>
      </div>
    </template>
    <template v-else>
      <div class="tw-flex tw-flex-row tw-justify-between">
        <PaymentMethodIcon
          :payment-method="brand"
          width="54px"
        />
        <b-button
          variant="primary"
          :disabled="isEditing"
          @click="onEditClick"
        >
          Edit
        </b-button>
      </div>
      <div>{{ holderName }}</div>
      <div class="tw-flex tw-justify-between">
        <div>
          **** **** **** {{ number }}
        </div>
        <div>
          Card expires at {{ expMonth }}/{{ expYear }}
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
  .credit-card {
    // make transparent background for dark mode white for light mode black
    background-color: rgb(0, 0, 0, 15%);
    border-radius: 6px;
  }
</style>
