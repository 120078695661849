<script>

import CurrentPlan from '@/views/account-setting/components/billing-plans/CurrentPlan.vue'
import SubscriptionPlans from '@/views/account-setting/components/billing-plans/SubscriptionPlans.vue'

export default {
  name: 'Subscription',
  components: {
    SubscriptionPlans,
    CurrentPlan,
  },
}
</script>

<template>
  <div class="tw-flex tw-flex-col">
    <SubscriptionPlans />
    <CurrentPlan />
  </div>
</template>

<style scoped lang="scss">

</style>
