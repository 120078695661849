var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"profileFrom"},[_c('b-media',{attrs:{"no-body":""}},[_c('b-media-aside',[_c('b-link',[_c('b-avatar',{ref:"previewEl",staticStyle:{"height":"80px","width":"80px"},attrs:{"rounded":"","src":_vm.previewFile}})],1)],1),_c('b-media-body',{staticClass:"mt-75 ml-75"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-75 mr-75",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.$refs.refInputEl.$el.click()}}},[_vm._v(" Upload ")]),_c('b-form-file',{key:_vm.fileInputKey,ref:"refInputEl",attrs:{"accept":"image/*","hidden":true,"plain":""},on:{"input":_vm.inputImageRenderer},model:{value:(_vm.profileFile),callback:function ($$v) {_vm.profileFile=$$v},expression:"profileFile"}}),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-75 mr-75",attrs:{"variant":"outline-secondary","size":"sm"},on:{"click":_vm.onResetAvatarClick}},[_vm._v(" Reset ")]),_c('b-card-text',[_vm._v("Allowed JPG, JPEG or PNG. Max size of 800kB")])],1)],1),_c('b-form',{staticClass:"mt-2 tw-grid tw-grid-cols-2 tw-gap-3",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-form-group',{staticClass:"tw-col-span-2",attrs:{"label":"Company Name","label-for":"company_name"}},[_c('validation-provider',{attrs:{"name":"Company Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Company Name","name":"company_name"},model:{value:(_vm.optionsLocal.companyName),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "companyName", $$v)},expression:"optionsLocal.companyName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"First Name","label-for":"first_name"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"First Name","name":"first_name"},model:{value:(_vm.optionsLocal.firstName),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "firstName", $$v)},expression:"optionsLocal.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Last Name","label-for":"last-name"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Last Name","name":"last_name"},model:{value:(_vm.optionsLocal.lastName),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "lastName", $$v)},expression:"optionsLocal.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Time zone","label-for":"timezone"}},[_c('validation-provider',{attrs:{"name":"timezone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","clearable":false,"options":_vm.timezoneOptions},model:{value:(_vm.timezone),callback:function ($$v) {_vm.timezone=$$v},expression:"timezone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Currency","label-for":"currency"}},[_c('validation-provider',{attrs:{"name":"currency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","clearable":false,"options":_vm.currencyOptions},model:{value:(_vm.currency),callback:function ($$v) {_vm.currency=$$v},expression:"currency"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Save changes ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2",attrs:{"variant":"outline-secondary","type":"reset"},on:{"click":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_vm._v(" Reset ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }