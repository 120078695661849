<template>
  <b-card>
    <ShopConnection />
  </b-card>
</template>
<script>
import { BCard } from 'bootstrap-vue'
import ShopConnection from '@/components/ShopConnection.vue'

export default {
  name: 'AccountSettingAmazonMarketplace',
  components: {
    ShopConnection,
    BCard,
  },
}
</script>

<style scoped>

</style>
