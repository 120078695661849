<script>
import { BCard, BCardTitle } from 'bootstrap-vue'
import {
  DxColumn, DxDataGrid, DxHeaderFilter, DxPaging, DxScrolling,
} from 'devextreme-vue/data-grid'
import LedgersDatasource from '@/data/datasources/ledgers.datasource'

export default {
  name: 'LedgersDataGrid',
  components: {
    DxPaging,
    DxScrolling,
    DxHeaderFilter,
    DxDataGrid,
    BCardTitle,
    DxColumn,
    BCard,
  },
  data() {
    return {
      noResultsFoundMessage: 'No data found',
      dataSource: LedgersDatasource.getInstance(this.$store, this.$bvToast),
    }
  },
}
</script>

<template>
  <b-card>
    <b-card-title>Ledgers</b-card-title>
    <DxDataGrid
      ref="gridView"
      class="sxr-grid regular-text-color"
      :data-source="dataSource"
      :show-borders="false"
      :show-row-lines="true"
      :show-column-lines="false"
      :remote-operations="true"
      :word-wrap-enabled="true"
      :show-column-headers="true"
      :no-data-text="noResultsFoundMessage"
      width="100%"
      height="40vh"
    >
      <DxHeaderFilter
        :visible="false"
        :hide-select-all-on-search="false"
      />
      />
      <DxPaging :page-size="25" />
      <DxScrolling
        mode="virtual"
        :use-native="false"
      />
      <DxColumn
        data-field="point"
        caption="Points"
        data-type="currency"
        :allow-sorting="false"
        alignment="center"
      />
      <DxColumn
        data-field="description"
        data-type="string"
        :allow-sorting="false"
        alignment="center"
      />
      <DxColumn
        data-field="created_at"
        data-type="datetime"
        :allow-sorting="false"
        alignment="center"
      />
    </DxDataGrid>
  </b-card>
</template>

<style scoped lang="scss">

</style>
