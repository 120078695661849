<template>
  <b-tabs
    v-model="activeTabIndex"
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
    @input="changeRoute"
  >

    <!-- general tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">General</span>
      </template>

      <account-setting-general
        v-if="options.general"
        :general-data="options.general"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- general tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Amazon Marketplace</span>
      </template>

      <account-setting-amazon-marketplace />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Change Password</span>
      </template>

      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->

    <!-- Subscription tab -->
    <b-tab
      :disabled="!isConnected"
    >

      <!-- title -->
      <template #title>
        <feather-icon
          icon="FileTextIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Subscription</span>
      </template>

      <subscription v-if="isConnected" />
    </b-tab>
    <!--/ Subscription -->

    <!-- Billing tab -->
    <b-tab
      :disabled="!isConnected"
    >

      <!-- title -->
      <template #title>
        <feather-icon
          icon="FileTextIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Billing</span>
      </template>

      <billing v-if="isConnected" />
    </b-tab>
    <!--/ Billing tab -->

    <!-- Referral tab -->
    <b-tab
      :disabled="!isConnected"
    >

      <!-- title -->
      <template #title>
        <ReferralIcon :size="20" />
        <span class="font-weight-bold">Referral</span>
      </template>

      <Referral
        v-if="isConnected"
        :referral-code="options.general.referralCode"
        :balance="options.general.balance"
      />
    </b-tab>
    <!--/ Referral tab -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingAmazonMarketplace from '@/views/account-setting/AccountSettingAmazonMarketplace.vue'
import Subscription from '@/views/account-setting/Subscription.vue'
import Billing from '@/views/account-setting/Billing.vue'
import Referral from '@/views/account-setting/Referral.vue'
import ReferralIcon from '@/components/icons/ReferralIcon.vue'

export default {
  components: {
    ReferralIcon,
    Referral,
    Subscription,
    AccountSettingAmazonMarketplace,
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    Billing,
  },
  props: {
    activeTab: {
      type: String,
      default: 'general',
    },
  },
  data() {
    return {
      options: {
        general: {},
      },
      activeTabIndex: 0,
      tabs: [
        {
          index: 0,
          title: 'General',
          slug: 'general',
          url: '/profile/general',
        },
        {
          index: 1,
          title: 'Amazon Marketplace',
          slug: 'amazon-marketplace',
          url: '/profile/amazon-marketplace',
        },
        {
          index: 2,
          title: 'Change Password',
          slug: 'change-password',
          url: '/profile/change-password',
        },
        {
          index: 3,
          title: 'Billing & Plans',
          slug: 'billing-and-plans',
          url: '/profile/billing-and-plans',
        },
        {
          index: 4,
          title: 'Subscription',
          slug: 'subscription',
          url: '/profile/subscription',
        },
        {
          index: 5,
          title: 'Referral',
          slug: 'referral',
          url: '/profile/referral',
        },
      ],
    }
  },
  computed: {
    isConnected() {
      return this.$store.getters['auth/isStoreConnected']
    },
    isSynchronized() {
      return this.$store.getters['auth/isSynchronized']
    },
  },
  watch: {
    $route(to) {
      const activeTabIndex = this.tabs.find(tab => tab.slug === to.params.activeTab).index
      if (this.activeTabIndex !== activeTabIndex) {
        this.activeTabIndex = activeTabIndex
      }
    },
  },
  created() {
    this.activeTabIndex = this.tabs.find(tab => tab.slug === this.activeTab).index
    this.options.general = this.$store.getters['auth/userGeneral']()
  },
  methods: {
    changeRoute(tabIndex) {
      const tabDetails = this.tabs.find(tab => tab.index === tabIndex)
      if (this.$router.currentRoute.params.activeTab !== tabDetails.slug) {
        this.$router.push({ name: 'account-setting', params: { activeTab: tabDetails.slug } })
      }
    },
  },
}
</script>
