<template>
  <b-card>

    <validation-observer
      ref="profileFrom"
    >

      <!-- media -->
      <b-media no-body>
        <b-media-aside>
          <b-link>
            <b-avatar
              ref="previewEl"
              style="height: 80px; width: 80px;"
              rounded
              :src="previewFile"
            />
          </b-link>
          <!--/ avatar -->
        </b-media-aside>

        <b-media-body class="mt-75 ml-75">
          <!-- upload button -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mb-75 mr-75"
            @click="$refs.refInputEl.$el.click()"
          >
            Upload
          </b-button>
          <b-form-file
            :key="fileInputKey"
            ref="refInputEl"
            v-model="profileFile"
            accept="image/*"
            :hidden="true"
            plain
            @input="inputImageRenderer"
          />
          <!--/ upload button -->

          <!-- reset -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            size="sm"
            class="mb-75 mr-75"
            @click="onResetAvatarClick"
          >
            Reset
          </b-button>
          <!--/ reset -->
          <b-card-text>Allowed JPG, JPEG or PNG. Max size of 800kB</b-card-text>
        </b-media-body>
      </b-media>
      <!--/ media -->

      <!-- form -->
      <b-form
        class="mt-2 tw-grid tw-grid-cols-2 tw-gap-3"
        @submit.prevent="validationForm"
      >
        <b-form-group
          class="tw-col-span-2"
          label="Company Name"
          label-for="company_name"
        >

          <validation-provider
            #default="{ errors }"
            name="Company Name"
            rules="required"
          >
            <b-form-input
              v-model="optionsLocal.companyName"
              placeholder="Company Name"
              name="company_name"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="First Name"
          label-for="first_name"
        >

          <validation-provider
            #default="{ errors }"
            name="First Name"
            rules="required"
          >
            <b-form-input
              v-model="optionsLocal.firstName"
              placeholder="First Name"
              name="first_name"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Last Name"
          label-for="last-name"
        >
          <validation-provider
            #default="{ errors }"
            name="Last Name"
            rules="required"
          >
            <b-form-input
              v-model="optionsLocal.lastName"
              placeholder="Last Name"
              name="last_name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Time zone"
          label-for="timezone"
        >
          <validation-provider
            #default="{ errors }"
            name="timezone"
            rules="required"
          >
            <v-select
              v-model="timezone"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :clearable="false"
              :options="timezoneOptions"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Currency"
          label-for="currency"
        >
          <validation-provider
            #default="{ errors }"
            name="currency"
            rules="required"
          >
            <v-select
              v-model="currency"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :clearable="false"
              :options="currencyOptions"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-row>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              class="mt-2 mr-1"
            >
              Save changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetForm"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import moment from 'moment-timezone'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import timezones from '@/data/timezone.data'

export default {
  components: {
    BButton,
    BForm,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BAvatar,
    vSelect,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      timezone: {
        id: JSON.parse(JSON.stringify(this.generalData)).timezone,
      },
      currency: {
        id: JSON.parse(JSON.stringify(this.generalData)).currency,
      },
      fileInputKey: 0,
      profileFile: null,
      currencyOptions: [
        {
          id: 'USD',
          title: 'USD',
        },
        {
          id: 'EUR',
          title: 'EUR',
        },
        {
          id: 'GBP',
          title: 'GBP',
        },
        {
          id: 'CAD',
          title: 'CAD',
        },
        {
          id: 'MXN',
          title: 'MXN',
        },
        {
          id: 'AUD',
          title: 'AUD',
        },
        {
          id: 'JPY',
          title: 'JPY',
        },
        {
          id: 'INR',
          title: 'INR',
        },
        {
          id: 'SEK',
          title: 'SEK',
        },
        {
          id: 'BRL',
          title: 'BRL',
        },
        {
          id: 'EGP',
          title: 'EGP',
        },
        {
          id: 'AED',
          title: 'AED',
        },
        {
          id: 'SAR',
          title: 'SAR',
        },
        {
          id: 'SGD',
          title: 'SGD',
        },
        {
          id: 'TRY',
          title: 'TRY',
        },
      ],
    }
  },
  computed: {
    timezoneOptions() {
      return timezones.map(timezone => {
        const datetime = moment()
        datetime.tz(timezone)
        const z = datetime.format('Z')
        const time = datetime.format('h:mm A')
        return {
          id: timezone,
          title: `(UTC${z}) ${timezone} - ${time}`,
        }
      })
    },
    previewFile() {
      return this.preview ?? this.optionsLocal.avatar
    },
  },
  mounted() {
    const timezone = JSON.parse(JSON.stringify(this.generalData)).timezone ?? undefined
    if (timezone) {
      this.timezone = this.prepareTimezoneOption(timezone)
    } else {
      this.timezone = this.prepareTimezoneOption(moment.tz.guess())
    }
    const currency = JSON.parse(JSON.stringify(this.generalData)).currency ?? undefined
    if (currency) {
      this.currency = {
        id: currency,
        title: currency,
      }
    } else {
      this.currency = {
        id: 'USD',
        title: 'USD',
      }
    }
  },
  methods: {
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },
    prepareTimezoneOption(timezone) {
      const datetime = moment()
      datetime.tz(timezone)
      const z = datetime.format('Z')
      const time = datetime.format('h:mm A')
      return {
        id: timezone,
        title: `(UTC${z}) ${timezone} - ${time}`,
      }
    },
    validationForm() {
      this.$refs.profileFrom.validate().then(success => {
        if (success) {
          this.loading = true
          const payload = {
            firstName: this.optionsLocal.firstName,
            lastName: this.optionsLocal.lastName,
            timezone: this.timezone.id,
            file: this.profileFile,
            currency: this.currency.id,
            companyName: this.optionsLocal.companyName,
          }
          this.$store.dispatch('user/updateProfile', payload).then(data => {
            this.$bvToast.toast(data.message, {
              title: 'Success',
              toaster: 'b-toaster-top-center',
              variant: 'success',
              solid: true,
              appendToast: false,
            })
            this.$store.commit('auth/updateUserProfile', {
              firstName: data.first_name,
              lastName: data.last_name,
              timezone: data.timezone,
              avatar: data.avatar,
              companyName: data.company_name,
            })
          }).catch(() => {
            this.$refs.profileFrom.setErrors({
              currency: 'Server error, please try again later',
            })
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
    onResetAvatarClick() {
      this.fileInputKey += 1
      this.preview = null
      this.profileFile = null
    },
  },
  setup() {
    const refInputEl = ref(null)
    const preview = ref(null)
    const setPreview = value => { preview.value = value }
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, setPreview)

    return {
      refInputEl,
      preview,
      setPreview,
      inputImageRenderer,
    }
  },
}
</script>
