<script>

import { BCard, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Card from '@/views/account-setting/components/billing-plans/components/Card.vue'
import SpinnerLoader from '@/components/SpinnerLoader.vue'
import EventBus from '@/utils/event-bus'

export default {
  name: 'PaymentMethods',
  components: {
    SpinnerLoader, Card, BCard,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      isLoading: false,
      cards: [],
      isEditingPaymentMethodId: null,
    }
  },
  computed: {
    isCardAvailable() {
      return this.cards.length
    },
  },
  created() {
    EventBus.$on('ws_payment_method_changed', () => {
      this.fetchData()
      if (this.connectWindow) {
        this.connectWindow.close()
      }
    })
  },
  destroyed() {
    EventBus.$off('ws_payment_method_changed')
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.isLoading = true
      this.$store.dispatch('subscription/fetchPaymentMethods').then(result => {
        this.cards = result
      }).finally(() => {
        this.isLoading = false
      })
    },
    onPaymentMethodChange(paymentMethodId) {
      this.isEditingPaymentMethodId = paymentMethodId
      this.$store.dispatch('subscription/changePaymentMethod', { payment_method_id: paymentMethodId }).then(result => {
        const width = 700
        const height = 750
        const left = (window.screen.availWidth / 2) - (width / 2)
        const top = (window.screen.availHeight / 2) - (height / 2)
        const features = `location=yes,height=${height},width=${width},scrollbars=yes,status=yes,left=${left},top=${top}screenX=${left},screenY=${top}`
        this.connectWindow = window.open(result, '_blank', features)
      }).finally(() => {
        this.isEditingPaymentMethodId = null
      })
    },
  },
}
</script>

<template>
  <b-card class="container">
    <template v-if="!isLoading">
      <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-3">
        <template
          v-if="isCardAvailable"
        >
          <Card
            v-for="(card, index) in cards"
            :key="index"
            :is-editing="isEditingPaymentMethodId === card.id"
            :card-id="card.id"
            :holder-name="card.card_holder_name"
            :number="card.card_number"
            :exp-month="card.expiration_month"
            :exp-year="card.expiration_year"
            :brand="card.brand"
            @on-edit-click="onPaymentMethodChange"
          />
        </template>
        <Card
          v-else
          dummy
        />
      </div>
    </template>
    <template
      v-else
    >
      <SpinnerLoader style="height: 200px" />
    </template>
  </b-card>
</template>

<style scoped lang="scss">
  .container {
    min-height: 205px;

  }
</style>
