<script>

import PaymentMethods from '@/views/account-setting/components/billing-plans/PaymentMethods.vue'
import Invoices from '@/views/account-setting/components/billing-plans/Invoices.vue'

export default {
  name: 'Billing',
  components: {
    Invoices, PaymentMethods,
  },
}
</script>

<template>
  <div class="tw-flex tw-flex-col">
    <PaymentMethods />
    <Invoices />
  </div>
</template>

<style scoped lang="scss">

</style>
