<script>

import {
  BCard, BCardTitle, BButton, VBTooltip, BProgress, BProgressBar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SpinnerLoader from '@/components/SpinnerLoader.vue'
import EventBus from '@/utils/event-bus'

export default {
  name: 'CurrentPlan',
  components: {
    BProgressBar,
    BProgress,
    SpinnerLoader,
    BCard,
    BCardTitle,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      isSubscribeLoading: false,
      debouncedAction: null,
      isCurrentPlanLoaded: false,
      isNextSubscriptionPlanLoaded: false,
      nextSubscriptionPlan: {
        id: null,
        title: null,
        description: null,
        price: null,
        unit: null,
        ordersData: {
          count: null,
          startDate: null,
          endDate: null,
        },
      },
      planDetails: {
        name: 'Trial',
        description: null,
        activeSince: null,
        activeUntil: null,
        trialSince: null,
        trialUntil: null,
        isActive: false,
      },
    }
  },
  computed: {
    isLoading() {
      return !this.isCurrentPlanLoaded || !this.isNextSubscriptionPlanLoaded
    },
    isActive() {
      return this.planDetails.isActive && this.planDetails.activeUntil
    },
    untilDate() {
      if (this.planDetails.activeUntil) {
        return this.planDetails.activeUntil
      }
      if (this.planDetails.trialUntil) {
        return this.planDetails.trialUntil
      }
      return null
    },
    sinceDate() {
      if (this.planDetails.activeSince) {
        return this.planDetails.activeSince
      }
      if (this.planDetails.trialSince) {
        return this.planDetails.trialSince
      }
      return null
    },
    pastDays() {
      if (this.sinceDate) {
        const diffTime = Math.abs(new Date() - this.sinceDate)
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        return diffDays
      }
      return 0
    },
    totalDays() {
      if (this.untilDate && this.sinceDate) {
        const diffTime = Math.abs(this.untilDate - this.sinceDate)
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        return diffDays
      }
      return 0
    },
    remainingDays() {
      return this.totalDays - this.pastDays
    },
    percentDaysPassed() {
      if (this.totalDays) {
        return Math.round((this.pastDays / this.totalDays) * 100)
      }
      return 0
    },
    progressVariant() {
      if (this.remainingDays) {
        return 'success'
      }
      return 'danger'
    },
  },
  created() {
    EventBus.$on('ws_subscription_created', this.onSubscribed)
    EventBus.$on('ws_subscription_updated', this.onSubscriptionUpdated)
    this.loadData()
  },
  destroyed() {
    EventBus.$off('ws_subscription_created', this.onSubscribed)
    EventBus.$off('ws_subscription_updated', this.onSubscriptionUpdated)
  },
  methods: {
    loadData() {
      this.$store.dispatch('subscription/fetchCurrentSubscription').then(result => {
        this.isCurrentPlanLoaded = true
        if (result) {
          this.planDetails = {
            name: result.active_until ? result.name : 'Trial',
            description: result.description || null,
            activeSince: result.active_since ? new Date(result.active_since) : null,
            activeUntil: result.active_until ? new Date(result.active_until) : null,
            trialSince: result.trial_since ? new Date(result.trial_since) : null,
            trialUntil: result.trial_until ? new Date(result.trial_until) : null,
            isActive: result.is_active,
          }
        }
      }).catch(() => {
        this.isCurrentPlanLoaded = true
      })

      this.$store.dispatch('subscription/fetchAvailableSubscription').then(result => {
        this.isNextSubscriptionPlanLoaded = true
        if (result) {
          this.nextSubscriptionPlan = {
            id: result.id,
            title: result.title,
            description: result.description,
            price: result.price,
            unit: result.unit,
            ordersData: {
              count: result.orders_data.count,
              startDate: new Date(result.orders_data.start_date),
              endDate: new Date(result.orders_data.end_date),
            },
          }
        }
      }).catch(() => {
        this.isNextSubscriptionPlanLoaded = true
      })
    },
    onSubscribed() {
      this.$store.commit('auth/trial', false)
      this.loadData()
      if (this.popupWindow) {
        setTimeout(() => { this.popupWindow.close() }, 5000)
      }
    },
    onSubscriptionUpdated() {
      this.loadData()
    },
    formatShortDate(date) {
      if (!date) {
        return ''
      }
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })
    },
    formatDateMonthAndDay(date) {
      if (!date) {
        return ''
      }
      return date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
      })
    },
    onCancelSubscriptionClick() {
      if (!this.planDetails.isActive) return
      if (this.debouncedAction) clearTimeout(this.debouncedAction)
      this.debouncedAction = setTimeout(() => {
        this.isSubscribeLoading = true
        this.$store.dispatch('subscription/cancelSubscription').then(() => {
          this.$nextTick(() => {
            this.loadData()
            this.$bvToast.toast('Subscription canceled successfully.', {
              title: 'Success',
              variant: 'success',
              solid: true,
            })
          })
        }).catch(() => {
          this.$bvToast.toast('Something went wrong. Please try again later.', {
            title: 'Error',
            variant: 'danger',
            solid: true,
          })
        }).finally(() => {
          this.isSubscribeLoading = false
        })
      }, 500)
    },
    onSubscribeClick() {
      if (!this.nextSubscriptionPlan.id) return
      if (this.debouncedAction) clearTimeout(this.debouncedAction)
      this.debouncedAction = setTimeout(() => {
        this.isSubscribeLoading = true
        this.$store.dispatch('subscription/subscribe', { price_id: this.nextSubscriptionPlan.id }).then(result => {
          const width = 700
          const height = 750
          const left = (window.screen.availWidth / 2) - (width / 2)
          const top = (window.screen.availHeight / 2) - (height / 2)
          const features = `location=yes,height=${height},width=${width},scrollbars=yes,status=yes,left=${left},top=${top}screenX=${left},screenY=${top}`
          this.popupWindow = window.open(result, '_blank', features)
        }).catch(() => {
          this.$bvToast.toast('Something went wrong. Please try again later.', {
            title: 'Error',
            variant: 'danger',
            solid: true,
          })
        }).finally(() => {
          this.isSubscribeLoading = false
        })
      }, 500)
    },
    numberFormat(number) {
      return new Intl.NumberFormat('en-US').format(number)
    },
  },
}
</script>

<template>
  <b-card class="container">
    <template v-if="!isLoading">
      <b-card-title>Current Plan</b-card-title>
      <div class="tw-flex tw-flex-col tw-gap-2">
        <div class="tw-grid tw-grid-cols-1">
          <div class="tw-flex tw-flex-col tw-gap-2">
            <div class="tw-text-xl">
              Your Current Plan is <b>{{ planDetails.name }}.</b>
            </div>
            <div
              v-if="planDetails.activeUntil"
              v-html="planDetails.description"
            />
            <div v-else>
              Experience our premium service with a free trial! Get full access to all features and benefits, no strings attached
            </div>

            <div
              v-if="planDetails.activeUntil"
              class="tw-text-xl"
            >
              Active until <b>{{ formatShortDate(planDetails.activeUntil) }}.</b>
            </div>
            <div
              v-else-if="planDetails.trialUntil"
              class="tw-text-xl"
            >
              <b>Free Trial</b> until <b>{{ formatShortDate(planDetails.trialUntil) }}.</b>
            </div>
            <div
              v-if="planDetails.trialUntil || planDetails.activeUntil"
              class="tw-flex tw-flex-col tw-gap-2 tw-mt-2"
            >
              <div class="tw-flex tw-justify-between">
                <div class="tw-font-bold">
                  Days
                </div>
                <div class="tw-font-bold">
                  {{ pastDays }} of {{ totalDays }} Days
                </div>
              </div>
              <b-progress
                :max="100"
                :variant="progressVariant"
                height="1rem"
              >
                <b-progress-bar
                  :value="percentDaysPassed"
                />
              </b-progress>
              <div v-if="planDetails.trialUntil">
                <span class="tw-font-bold">{{ remainingDays }}</span> days remain until your plan will require an update.
              </div>
              <div v-else>
                <span class="tw-font-bold">{{ remainingDays }}</span> days remain until your plan will recalculate. Subscription price may change.
              </div>
            </div>
            <div
              v-if="!isActive && nextSubscriptionPlan"
              class="tw-flex tw-flex-col"
            >
              <p>Based on your previous month of sales ( <span class="tw-text-xl tw-font-bold text-primary">{{ numberFormat(nextSubscriptionPlan.ordersData.count) }}</span> Orders for {{ formatDateMonthAndDay(nextSubscriptionPlan.ordersData.startDate) }} through {{ formatDateMonthAndDay(nextSubscriptionPlan.ordersData.endDate) }} ), you will be subscribed to ( <span class="tw-font-bold">{{ nextSubscriptionPlan.title }}</span> ) at a cost of <span class="tw-text-xl tw-font-bold text-primary">{{ nextSubscriptionPlan.price.display }} </span><span class="tw-font-bold"> / {{ nextSubscriptionPlan.unit.replace('per', '') }}</span>. This will be recalculated monthly.</p>
            </div>
            <div v-if="isActive && nextSubscriptionPlan">
              <p>Based on your previous month of sales ( <span class="tw-text-xl tw-font-bold text-primary">{{ numberFormat(nextSubscriptionPlan.ordersData.count) }}</span> Orders for {{ formatDateMonthAndDay(nextSubscriptionPlan.ordersData.startDate) }} through {{ formatDateMonthAndDay(nextSubscriptionPlan.ordersData.endDate) }} ), you are pacing to be subscribed to ( <span class="tw-font-bold">{{ nextSubscriptionPlan.title }}</span> ) at a cost of <span class="tw-text-xl tw-font-bold text-primary">{{ nextSubscriptionPlan.price.display }} </span><span class="tw-font-bold"> / {{ nextSubscriptionPlan.unit.replace('per', '') }}</span>. This will be recalculated monthly.</p>
            </div>
          </div>
        </div>
        <div class="tw-flex tw-mt-2">
          <b-button
            v-if="isActive"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            style="width: 173px"
            variant="danger"
            size="sm"
            @click="onCancelSubscriptionClick"
          >
            <template v-if="isSubscribeLoading">
              <SpinnerLoader
                style="height: 1rem"
                class="tw-mr-2"
              />
            </template>
            <template v-else>
              Cancel My Subscription
            </template>
          </b-button>
          <b-button
            v-else
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            style="width: 173px"
            variant="primary"
            size="sm"
            @click="onSubscribeClick"
          >
            <template v-if="isSubscribeLoading">
              <SpinnerLoader
                style="height: 1rem"
                class="tw-mr-2"
              />
            </template>
            <template v-else>
              Subscribe Now
            </template>
          </b-button>
        </div>
      </div>
    </template>
    <template v-else>
      <SpinnerLoader style="height: 200px" />
    </template>
  </b-card>
</template>

<style scoped lang="scss">
  .container {
    min-height: 200px;
  }
</style>
