<script>

import { BCard } from 'bootstrap-vue'

export default {
  name: 'SubscriptionPlans',
  components: { BCard },
  data() {
    return {
      subscriptionPlans: [],
      isLoading: false,
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.isLoading = true
      this.$store.dispatch('subscription/fetchSubscriptionPlans').then(({ items }) => {
        this.subscriptionPlans = items || []
      }).finally(() => {
        this.isLoading = false
      })
    },
    makeNumberAsString(number) {
      if (number > 999) {
        return `${(number / 1000).toFixed(0)}K`
      }
      return number
    },
  },
}
</script>

<template>
  <b-card class="container">
    <template v-if="!isLoading">
      <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-4">
        <div
          v-for="plan in subscriptionPlans"
          :key="plan.title"
          class="tw-flex tw-flex-col tw-gap-4 tw-justify-center tw-items-center tw-p-4 border tw-rounded"
        >
          <div class="tw-font-bold tw-text-xl">
            {{ plan.title }}
          </div>

          <div class="tw-font-bold">
            <span class="tw-text-3xl tw-font-bold text-primary">{{ plan.price.display }}</span> <sub>/ {{ plan.unit.replace('per', '') }}</sub>
          </div>
          <div
            v-if="plan.max_number_of_orders"
            class="tw-font-bold text-primary plan-orders-details tw-w-full tw-p-1 tw-text-center"
          >
            {{ makeNumberAsString(plan.min_number_of_orders || 1) }} to {{ makeNumberAsString(plan.max_number_of_orders) }} orders per month
          </div>
          <div
            v-else
            class="tw-font-bold text-primary plan-orders-details tw-w-full tw-p-1 tw-text-center"
          >
            Unlimited
          </div>
        </div>
      </div>
    </template>
  </b-card>
</template>

<style scoped lang="scss">
  .plan-orders-details {
    background: rgba(0, 165, 68, 0.12);
  }
</style>
