<script>
import {
  BCard, BInputGroup, BInputGroupAppend, BFormInput, BFormGroup, VBTooltip,
} from 'bootstrap-vue'
import CoinsIcon from '@/components/icons/CoinsIcon.vue'
import LedgersDataGrid from '@/views/account-setting/components/referral/LedgersDataGrid.vue'

export default {
  name: 'Referral',
  components: {
    LedgersDataGrid,
    CoinsIcon,
    BFormGroup,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    balance: {
      type: Number,
      default: 0,
    },
    referralCode: {
      type: String,
      default: null,
    },
  },
  computed: {
    referralUrl() {
      return `${window.location.origin}/sign-up?referral_code=${this.referralCode}`
    },
  },
  methods: {
    async copyToClipboard(value) {
      await navigator.clipboard.writeText(value)
      this.$bvToast.toast(value, {
        title: 'Copy To Clipboard',
        variant: 'info',
        solid: true,
      })
    },
  },
}
</script>

<template>
  <div class="tw-flex tw-flex-col">
    <b-card class="tw-flex tw-flex-col tw-gap-4">
      <div class="tw-flex tw-flex-row">
        <div class="tw-text-xl tw-font-bold text-primary">
          <CoinsIcon size="52" />
        </div>
        <div class="tw-flex tw-flex-col">
          <div class="tw-ml-2">
            Account Balance:
          </div>
          <div class="tw-flex tw-flex-row tw-items-baseline">
            <div class="tw-text-3xl tw-font-bold text-primary tw-ml-2 tw-mr-1">
              {{ balance }}
            </div>
            <div class="text-primary">
              point
            </div>
          </div>
        </div>
      </div>
      <b-form-group
        class="tw-w-1/3 tw-mt-4"
        label-for="referral-url"
      >
        <b-input-group>
          <b-form-input
            id="referral-url"
            class="input-group-merge"
            :value="referralUrl"
            readonly
          />
          <b-input-group-append is-text>
            <feather-icon
              class="tw-cursor-pointer"
              icon="CopyIcon"
              size="18"
              @click="copyToClipboard(referralUrl)"
            />
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <p>
        Earn points effortlessly by referring friends to subscribe! For every new subscriber you bring on board, you'll accumulate points that can be redeemed for exciting rewards. Start referring today and enjoy the perks of our loyalty program!
      </p>
    </b-card>
    <LedgersDataGrid />
  </div>
</template>

<style scoped lang="scss">
  .text-primary {
    ::v-deep {
      path {
        fill: var(--primary) !important;
      }
    }
  }
</style>
